
.Ini{
    height: 100vh; 
    width: 100;  
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.IniEntrar{
    position:relative;
    top: 200px;
}
.video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.boton:hover {
    color: rgb(14, 11, 11);
    background-color:rgb(214, 214, 214);
}
.boton {
    
    border-radius: 5px;
    padding: 2px; /*espacio alrededor texto*/
    background-color: rgb(250,243,243); /*color botón*/
    border-bottom: 30px;
    }