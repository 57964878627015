html, body, #root, .app {
  height: 100%;
  margin: 0;
}

.app {
  display: grid;
  align-items: flex-start;
}

.makeStyles-home-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}